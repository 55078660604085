import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "뜨거워지는 지구, 싸늘하게 식은 관심",
  "slug": "data11",
  "date": "2022-03-23T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb11.png",
  "featuredImg": "../images/featured/featured11.png",
  "summary": "기후 변화에 대한 한국인의 속마음 무엇 일까?",
  "tags": ["한국사회", "환경", "지구"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`작은 빙하 조각 위에 아슬아슬하게 올라 서 있는 북극곰의 애처로운 모습은 기후 변화의 심각성을 표현하는 상징이다. 기후 변화는 북극곰뿐 아니라 인류의 운명을 위협하는 전 지구적 과제다. 지금보다 지구 온도가 0.5도만 더 올라가도 심각한 상황이 온다. 그러나 실천적 관심은 매우 부족하다. 우리나라에서 기후 변화에 관한 정부 간 협의체(IPCC) 총회가 열린 것을 아는 사람은 매우 소수다. 미국 알래스카와 유럽의 살인적 폭염에 대해서도 냉담하다. 기후 변화에 대한 한국인의 속마음 무엇 일까?`}</p>

    <h2>{`각 주체의 기후 변화 대응 노력 평가`}</h2>
    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`기후 변화에 대처하는 각 사회 주체들의 노력을 평가할 때 '자기 자신에게 가장 후한 점수를 주었다. 하지만 우리 국민 개개인의 점수는 낮게 나타났다. 이러한 모순은 당위와 실제가 일치하지 않는 경향을 잘 보여준다.`}</p>
    <h3>{`심각성에 대해 공감하지만 우선순위 낮다.`}</h3>
    <p>{`조사 결과 기후 변화가 심각하다는 데 대해 대부분 공감한다. 그러나 이에 대한 기초 지식조차 부족한 현실이다. 현재 기후 변화는 인간이 과도하게 내뿜은 온실가스 때문이라는 것이 상식이지만, 기후 변화가 자연 변동 때문이라고 응답한 사람이 7.8%(19~29세 12.1%, 30대 16.9%)나 됐다. 사회 과제의 우선순위에 대해 답할때 기후 변화는 장기 과제로 꼽힌다.`}</p>
    <h6>{`*2019년 7월 1~2일 전국 만 19세 이상 남녀 1,000명을 대상으로 RDD 방식 자동 응답 조사(무선 100%)로 진행됐다. 표본오차는 95% 신뢰 수준에서 ±3.1%p다. <세계 일보>와 공공의창이 공동 기획하고 한국여론연구소가 조사를 수행했다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      